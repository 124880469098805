import React from "react";
import { getLogoUrl } from "utils/url.constants";

const NavLogo = ({ logoPath }) => {
  const logoUrl = logoPath
    ? window.location.origin + getLogoUrl(logoPath)
    : null;

  return (
    <img
      src={logoUrl}
      className="logo"
      style={{
        width: "100px",
      }}
    />
  );
};

export default NavLogo;
