import OneSignal from "react-onesignal";
import get from "lodash/get";

import { createPushSubscription } from "pages/Authentication/services";

let OneSignalClient;

class OneSignalUtilWrapper {
  constructor() {
    OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_APP_ID }).then(
      () => {
        OneSignal.Slidedown.promptPush();

        OneSignal.User.PushSubscription.addEventListener("change", (event) => {
          const currentToken = get(event, "current.id");
          if (!!currentToken) {
            this.checkAndCreatePushSubscription();
          }
        });
      }
    );
  }

  loginOneSignal = async (username) => {
    this.hasPermission();
    this.canRequestPermission();
    // this.triggerInAppMessage();
    OneSignal.login(username);
    this.checkAndCreatePushSubscription();
  };

  hasPermission = () => {
    let permission = OneSignal.Notifications.permission; // boolean
    return permission;
  };

  canRequestPermission = async () => {
    const canRequestPermission = OneSignal.Notifications.permission;
    if (!canRequestPermission) {
      console.log("Platform does not support notification");
    }
    return canRequestPermission;
  };

  getPushSubscriptionId = () => {
    const pushSubscriptionId = OneSignal.User.PushSubscription.id;
    return pushSubscriptionId;
  };

  checkAndCreatePushSubscription = () => {
    const pushSubscriptionId = this.getPushSubscriptionId();
    if (pushSubscriptionId) {
      createPushSubscription({ subscription_id: pushSubscriptionId });
    }
  };

  isPushSupported = () => {
    const isSupported = OneSignal.Notifications.isPushSupported();
    return isSupported;
  };

  logout = () => {
    OneSignal.logout();
  };
}

export const logoutOneSignal = () => {
  OneSignal.logout();
};

function getOrCreateOneSignalClient() {
  if (!OneSignalClient) {
    OneSignalClient = new OneSignalUtilWrapper();
  }
  return OneSignalClient;
}

export default getOrCreateOneSignalClient;
