import React, { useCallback, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";

import get from "lodash/get";
import size from "lodash/size";
import { format } from "date-fns";

import { KeyboardArrowDown } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";

import {
  getHomePath,
  getLoginPath,
  getElementConfigPage,
  getPlanningPage,
  getRegionPage,
  getTicketListPage,
  getUserListPage,
  getUserRolePage,
  getFeedbackLink,
  getChangePasswordPage,
  getProfilePage,
  getAnalysisPage,
  getAlertPage,
  getDevicesPage,
} from "utils/url.constants";

import {
  getIsAdminUser,
  getIsSuperAdminUser,
  getIsUserLoggedIn,
  getLoggedUserDetails,
  getLoggedUserLoginTime,
  getUserAvailableDashboards,
  getUserPermissions,
} from "redux/selectors/auth.selectors";
import { handleLogoutUser, logoutService } from "redux/actions/auth.actions";
import { fetchVersionData } from "App/services";

import NavLogo from "./NavLogo";

const NavigationBar = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { data: versionDetails } = useQuery("versionData", fetchVersionData, {
    staleTime: Infinity,
  });
  const logoPath = get(versionDetails, "logo_path");

  const isAdminUser = useSelector(getIsAdminUser);
  const isSuperAdminUser = useSelector(getIsSuperAdminUser);
  const permissions = useSelector(getUserPermissions);
  const availableDashboards = useSelector(getUserAvailableDashboards);
  const isUserLoggedIn = useSelector(getIsUserLoggedIn);
  const loggedUserDetails = useSelector(getLoggedUserDetails);
  const loginSince = useSelector(getLoggedUserLoginTime);

  const canUserView = get(permissions, "user_view", false) || isSuperAdminUser;
  const canRegionView =
    get(permissions, "region_view", false) || isSuperAdminUser;
  const canTicketView =
    get(permissions, "ticket_view", false) || isSuperAdminUser;
  const canViewDevices =
    get(permissions, "is_catv_user", false) || isSuperAdminUser;
  const canPlanningView =
    get(permissions, "planning_view", false) || isSuperAdminUser;
  const canSurveyView =
    get(permissions, "survey_view", false) || isSuperAdminUser;
  const canAnalysisView =
    get(permissions, "analysis_view", false) || isSuperAdminUser;
  const canAlertView =
    get(permissions, "alert_list_view", false) || isSuperAdminUser;

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuName, setMenuName] = useState(null);

  const handleMutationCallback = () => {
    dispatch(handleLogoutUser);
    navigate(getLoginPath());
  };

  const { mutate: logoutServiceMutation, isLoading } = useMutation(
    logoutService,
    {
      onSuccess: handleMutationCallback,
      onError: handleMutationCallback,
    }
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setMenuName(null);
  }, []);

  const handleMenuDropDownClick = useCallback(
    (name) => (e) => {
      setAnchorEl(e.currentTarget);
      setMenuName(name);
    },
    []
  );

  const showAdminMenu = !!anchorEl && menuName === "administration-menu";
  const showSettingsMenu = !!anchorEl && menuName === "settings-menu";
  const showDashboardMenu = !!anchorEl && menuName === "dashboard-menu";
  const showAdministration =
    isSuperAdminUser ||
    (isAdminUser && (canUserView || canRegionView || canTicketView));

  const menuContent = useMemo(() => {
    if (isUserLoggedIn) {
      return (
        <Stack direction="row" spacing={2}>
          {size(availableDashboards) <= 1 ? (
            <Button to={getHomePath()} component={Link} color="inherit">
              Dashboard
            </Button>
          ) : (
            <Button
              color="inherit"
              id="dashboard-button"
              onClick={handleMenuDropDownClick("dashboard-menu")}
              aria-controls={showDashboardMenu ? "dashboard-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={showDashboardMenu ? "true" : undefined}
              endIcon={<KeyboardArrowDown />}
            >
              Dashboards
            </Button>
          )}

          {canViewDevices ? (
            <Button component={Link} to={getDevicesPage()} color="inherit">
              Devices
            </Button>
          ) : null}
          {canPlanningView ? (
            <Button component={Link} to={getPlanningPage()} color="inherit">
              Network
            </Button>
          ) : null}
          {canAnalysisView ? (
            <Button component={Link} to={getAnalysisPage()} color="inherit">
              Analysis
            </Button>
          ) : null}
          {canAlertView ? (
            <Button component={Link} to={getAlertPage()} color="inherit">
              Alerts
            </Button>
          ) : null}
          {showAdministration ? (
            <Button
              color="inherit"
              id="administration-button"
              onClick={handleMenuDropDownClick("administration-menu")}
              aria-controls={showAdminMenu ? "administration-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={showAdminMenu ? "true" : undefined}
              endIcon={<KeyboardArrowDown />}
            >
              Administration
            </Button>
          ) : null}
          <Button
            sx={{
              padding: "2px 4px",
              textAlign: "left",
              textTransform: "initial",
            }}
            color="inherit"
            id="settings-button"
            onClick={handleMenuDropDownClick("settings-menu")}
            aria-controls={showSettingsMenu ? "settings-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={showSettingsMenu ? "true" : undefined}
            endIcon={<KeyboardArrowDown />}
          >
            <Box display="flex" alignItems="center">
              <Avatar
                sx={{
                  height: "34px",
                  width: "34px",
                }}
              >
                <PersonIcon />
              </Avatar>
              <Box lineHeight={1} pl={1}>
                <Typography variant="subtitle2" lineHeight={1.1}>
                  {loggedUserDetails.username}
                </Typography>
                {loginSince ? (
                  <Typography variant="caption">
                    Login since {format(new Date(loginSince), "dd/MM hh:mm")}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          </Button>
        </Stack>
      );
    } else {
      return (
        <Stack direction="row" spacing={2}>
          <Button to={getLoginPath()} component={Link} color="inherit">
            Login
          </Button>
        </Stack>
      );
    }
  }, [
    isUserLoggedIn,
    showAdministration,
    canPlanningView,
    canSurveyView,
    loggedUserDetails,
  ]);

  return (
    <AppBar position="static" id="navigation-bar">
      <Toolbar
        sx={{
          justifyContent: "space-between",
        }}
      >
        <NavLogo logoPath={logoPath} />
        {menuContent}
        <Menu
          id="dashboard-menu"
          anchorEl={anchorEl}
          MenuListProps={{
            "aria-labelledby": "dashboard-button",
          }}
          open={showDashboardMenu}
          onClose={handleClose}
        >
          {!!availableDashboards &&
            availableDashboards.map((dashConf) => {
              const { label, dash_key } = dashConf;
              return (
                <MenuItem
                  key={dash_key}
                  onClick={handleClose}
                  component={Link}
                  to={getHomePath(dash_key)}
                >
                  {label}
                </MenuItem>
              );
            })}
        </Menu>
        <Menu
          id="administration-menu"
          anchorEl={anchorEl}
          MenuListProps={{
            "aria-labelledby": "administration-button",
          }}
          open={showAdminMenu}
          onClose={handleClose}
        >
          {canRegionView ? (
            <MenuItem
              onClick={handleClose}
              component={Link}
              to={getRegionPage()}
            >
              Region Management
            </MenuItem>
          ) : null}
          {canUserView ? (
            <MenuItem
              onClick={handleClose}
              component={Link}
              to={getUserListPage()}
            >
              User Management
            </MenuItem>
          ) : null}
          {isSuperAdminUser ? (
            <MenuItem
              onClick={handleClose}
              component={Link}
              to={getUserRolePage()}
            >
              Role Management
            </MenuItem>
          ) : null}
          {canTicketView ? (
            <MenuItem
              onClick={handleClose}
              component={Link}
              to={getTicketListPage()}
            >
              Ticket Management
            </MenuItem>
          ) : null}
          {isSuperAdminUser ? (
            <MenuItem
              onClick={handleClose}
              component={Link}
              to={getElementConfigPage()}
            >
              Element Configuration
            </MenuItem>
          ) : null}
          {isSuperAdminUser ? (
            <MenuItem
              onClick={handleClose}
              component="a"
              href={getFeedbackLink()}
              target="__blank"
            >
              Feedback
            </MenuItem>
          ) : null}
        </Menu>
        <Menu
          id="settings-menu"
          anchorEl={anchorEl}
          MenuListProps={{
            "aria-labelledby": "settings-button",
          }}
          open={showSettingsMenu}
          onClose={handleClose}
        >
          <MenuItem
            onClick={handleClose}
            component={Link}
            to={getProfilePage()}
          >
            Profile
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            component={Link}
            to={getChangePasswordPage()}
          >
            Change Password
          </MenuItem>
          <MenuItem onClick={logoutServiceMutation}>
            {isLoading ? "Loading..." : "Logout"}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
